.main-container {
    position: absolute;
    height: calc(100% - $navbar-height);
    padding-left: $sidebar-button-width;
    padding-right: 0;
    top: $navbar-height;

    @media (max-width: 768px) {
        padding-left: 0;
    }
}
