$white: #ffffff;
$black: #000000;
$dark-gray: #a0a0a0;
$gray: #E1E1E1;
$light-gray: #f6f6f6;

// $pd-color-1: #244264; // #456966;

// $pd-color-1: #53859d; // #4c7e81;
// $pd-color-2: #5a9bd5;
// $pd-color-3: #75d0e2;
// $pd-color-4: #080a2c;
// $pd-color-5: #004070;
// $pd-color-6: #fff2d6;

$pd-color-1: #666673; // #4c7e81;
$pd-color-2: #FF9A0E;
$pd-color-3: #FF9A0E; // #bbbb95
$pd-color-4: #214675; // #222238
$pd-color-5: #214675; // #444460
$pd-color-6: #ffffff;
$pd-color-7: #214675;

$separator-color: $pd-color-5;


$blue: $pd-color-2;

$red: #d26a5f;
$yellow: #eac15d;

$striped-list-border-color: rgb(221, 221, 221);
$striped-list-background-color: #F7F7F7;

$current-state-color: #668DAB;
$complete-state-color: #9CC3E6;
$complete-last-state-color: #9CE6C3;
$next-state-color: #f5f5f5;

$selected-item-color: cornflowerblue;


$theme-colors: (
  "primary2": $pd-color-2,
);


$title-bg: $pd-color-2;
$title-fg: $gray;

$text-green: $pd-color-2;

$primary-card-bg: $pd-color-2;
$primary-card-bg2: $white;
$primary-text-color: $white;
$primary-text-color2: $gray;
$primary-text-color3: $pd-color-2;


$primary: $pd-color-4;
$secondary: $gray;

$sla-red: #ff0000;
$sla-green: #00ff00;
$sla-yellow: #ffff00;


$primary-text: white;

// $theme-colors: ("primary":#EC1E2A, "danger": #ff4136);

$border-radius: 3px;


// form style
$inline-input-gap: 1.5rem;
$inline-label-gap: .5rem;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1780px
);


$sidebar-width: 282px;
$sidebar-button-width: 64px;
$sidebar-content-width: $sidebar-width - $sidebar-button-width;


$main-container-gap: 24px;

// form tabs

// navbar
$navbar-color: #214675;
$navbar-bg-color: $white;
$navbar-badge-color: $white;
$navbar-height: 60px;

$pagination-color: $pd-color-4;
$pagination-bg: white;
$pagination-focus-bg: $pd-color-4;
$pagination-active-bg: $pd-color-4;
$paginaton-hover-color: $pd-color-4;
