@import './variables';

.div-login {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border: 1px solid $gray;
    border-radius: 20px;
    margin-top: 3.5rem;
    padding-left: 0;
    padding-right: 0;

    .card,
    .card-body {
        border-radius: 20px;
    }

    .login-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }

    #root__title {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    #root_password,
    #root_email {
        max-width: unset;
    }

    .login-btn-container {
        margin-top: 15px;
    }
}
