.search-bar-input {
    width: 250px;
    margin-bottom: 16px;

    &:focus-within {
        & .input-group-text {
            border-color: #4b84cc;
        }
    }

    & .input-group-text,
    & .form-control {
        border-radius: 100px;
    }

    & .input-group-text {
        background-color: white;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
    }

    & .form-control {
        font-size: 10pt;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;
        padding-left: 0;

        &:active,
        &:focus {
            box-shadow: 0;
            outline: 0;
        }
    }
}
