.clock {
    display: flex;
    align-items: center;
    height: 1em;
    line-height: 1em;

    > .time, > .date {
        font-size: 20px;
    }

    > .ampm {
        display: inline-block;
        padding-left: .25em;
        line-height: 1em;

        // &.is-am {
            // padding-bottom: 1em;
            // vertical-align: text-bottom;
        // }
    }
}
