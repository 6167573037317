.dashboard {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    .dashboard-widget {
        height: 100%;
        overflow: auto;
    }
}

.workTrayIcon {
    padding-bottom: 20px;

    img {
        cursor: pointer;
        max-width: 143px;
    }

    .label {
        p {
            font-size: 19px !important;
            margin: 0px;
            font-weight: bold;
        }
    }
}

.td-actionButtons {
    display: flex;
    justify-content: space-between;
    gap: 5px;
}

.worktray {
    height: 100%;
    width: 100%;
    background-color: white;
    padding: $main-container-gap;
    padding-bottom: 0;

    border-top-left-radius: 10px;
    border-top: 2px solid $gray;
    border-left: 2px solid $gray;

    @media (max-width: 768px) {
        padding-left: 0;
        border-left: 0;
        border-radius: 0;
    }

    .worktray-content {
        overflow: auto;
        height: 100%;
    }

    .worktray-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .worktray-action-btn {
            font-size: 10pt;
            height: min-content;
        }
    }

    .worktray-filters {
        display: flex;
        align-items: center;
        font-size: 10pt !important;
        gap: 8px;

        legend {
            margin: 0;
        }

        .form-group {
            margin-bottom: 0;
        }

        button[type="submit"],
        .control-label {
            display: none;
        }

        .prop-fields {
            display: flex;
            gap: 16px;
        }

        .filter-checkbox-input {
            position: relative;
            margin: 0;
            border-color: $gray;
        }

        .filter-btn,
        .filter-value {
            background-color: white;
        }

        .filter-input-group {
            flex-wrap: nowrap;
            position: relative;
            overflow: visible;

            &:focus-within {
                .filter-btn,
                .filter-delete,
                .filter-checkbox,
                .filter-input,
                .dropdown .btn {
                    border-color: #4b84cc;
                }
            }

            & .dropdown .btn {
                font-size: 10pt;
                height: min-content;
                background-color: white;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        .filter-btn,
        .filter-delete,
        .filter-key {
            border-radius: 100px;
        }
        .filter-dropdown-menu.dropdown-menu.show {
            top: auto !important;
            left: auto !important;
            transform: none !important;
            max-height: 350px;
            overflow-y: auto;
            z-index: 1000;
            width: auto;
        }

        .filter-dropdown-menu,
        .filter-btn,
        .filter-key,
        .filter-input,
        .filter-add,
        .filter-delete,
        .filter-checkbox,
        .filter-value,
        .filter-checkbox-wrapper {
            font-size: 10pt;
            height: min-content;
        }

        .filter-add,
        .filter-delete {
            border-color: #ced4da;
        }

        .filter-delete,
        .filter-checkbox {
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            padding-left: 10px;
        }

        .filter-checkbox-checked {
            color: black;
        }

        .filter-key {
            border-right: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .filter-input {
            width: 120px;
            border-right: 0;

            &:active,
            &:focus {
                box-shadow: 0;
            }
        }

        .filter-value {
            border-right: 0;
            border-radius: 0;
        }

        > .btn,
        .worktray-filters-title,
        .worktray-filters-btn-title {
            font-size: inherit !important;
        }

        .worktray-filters-title {
            color: gray;
        }

        .filter-btn-date {
            padding-left: 0;
            padding-top: 1px;
            padding-bottom: 1px;

            > .ant-picker {
                border: 0;
            }
        }

        .filter-checkbox-wrapper {
            cursor: unset;
            border: 1px solid $gray;
            border-radius: 100px;
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    > .card {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;

        .card-header {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
        }

        > .table {
            margin-top: 4px;
            margin-bottom: 0;

            th {
                border-top-width: 0;
            }

            > * > tr {
                > td,
                > th {
                    padding: 0.32rem 0rem 0.32rem 0.32rem;

                    > .btn {
                        white-space: nowrap;
                        text-overflow: clip;
                    }
                }
            }
        }
    }

    .worktray-action-btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
    }
}

.show-when-mobile {
    display: none;
}

.shift-viewer-card {
    background-color: $striped-list-background-color;
    box-shadow: 1.4px 2.9px 2.9px hsla(0, 0%, 0%, 0.198);

    &:hover {
        border-color: $primary;
    }
}
