.table-wrapper {
    &.table-fixed-header {
        overflow-y: auto;

        > .table {
            border-collapse: separate
        }
    }

    &.table-fixed-columns {
        overflow-x: auto;

        > .table {
            border-collapse: separate
        }
    }
}

.table {
    tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    td {
        vertical-align: middle;
        border-top: 0;
    } 
}
