@import './variables';

.filter-grid-container {
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;

    > p {
        margin-bottom: 0;
    }
    
    .filter-grid-row {
        display: grid;
        padding-bottom: 8px;

        .dropdown {
            flex-grow: 1;

            .btn {
                width: 100%;
            }
        }
    }

    .filter-grid-filters {
        width: 100%;
    }

    .filter-input-group {
        & .dropdown .btn {
            width: 100%;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}

/* Fine-tune the date header appearance */
.header2-text-label {
    font-weight: bold;
    font-size: 20px;
}
